import { type Event } from '@bugsnag/js';

import { type LoggerSeverity } from '@service-initiation/shared-clients-logger';

const loggerSeverityToBugsnagSeverityMap: Record<
  LoggerSeverity,
  Event['severity']
> = {
  error: 'error',
  info: 'info',
  warn: 'warning',
};

export const loggerSeverityToBugsnagSeverity = (severity: LoggerSeverity) => {
  const ospSeverity = loggerSeverityToBugsnagSeverityMap[severity];

  if (!ospSeverity) {
    throw new Error(`Unsupported Bugsnag severity: ${severity}`);
  }

  return ospSeverity;
};
