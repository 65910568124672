import { type Client as BrowserClient } from '@bugsnag/js';
import { type Client as NodeClient } from '@bugsnag/node';

import {
  type LoggerReport,
  type LoggerReporter,
  type LoggerSeverity,
} from '@service-initiation/shared-clients-logger';

import { loggerSeverityToBugsnagSeverity } from './utils';

export type BugsnagReporterOptions = {
  client: BrowserClient | NodeClient;
  reportableSeverities: LoggerSeverity[];
};

export class BugsnagReporter implements LoggerReporter {
  private client: BrowserClient | NodeClient;
  readonly reportableSeverities: LoggerSeverity[];

  constructor({
    reportableSeverities = ['error', 'warn'],
    ...options
  }: BugsnagReporterOptions) {
    this.reportableSeverities = reportableSeverities;
    this.client = options.client;
  }

  public process(report: LoggerReport) {
    this.client.notify(report.message, (event) => {
      event.severity = loggerSeverityToBugsnagSeverity(report.severity);
      if (report.metadata) {
        event.addMetadata('metadata', report.metadata);
      }
      if (report.context) {
        event.addMetadata('context', report.context);
      }
    });
  }
}
